import styled from "styled-components";
import Theme from "../styles/theme";

export const Container = styled.div`
  width: ${Theme.components.container.width};
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  @media (max-width: ${Theme.breakpoints.xl}) {
    padding: 0 20px;
  }
`;

export const Grid = styled(Container)<{ columns?: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.columns ? props.columns : 3}, 1fr);
  grid-gap: 30px;

  @media (max-width: ${Theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const AvatarImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #CCC;
  margin-right: 20px;

  @media (max-width: ${Theme.breakpoints.xl}) {
    margin-left: -1px;
    margin-right: -1px;
  }
`;
